<template>
  <div class="d-flex pa-2 video-container" no-gutters>
    <div  class="justify-right d-flex pl-2">
      <div
        v-if="this.init"
        class="video-image d-flex justify-space-between align-end"
        :style="`background-image: url(${video.thumb_url})`"
      >
        <v-chip color="#00000055" class="mb-3 mr-1 white--text" small label>
        {{this.$helpers.lengthInMinutes(this.video.duration)}}
        </v-chip>
        <v-btn
            icon
            color="gray"
            class="mb-2 ml-1"
            @click="onPlayVideo()"
        >
          <v-icon size="20"  color="white">mdi-play</v-icon>
        </v-btn>
      </div>
      <video
        ref="video"
        class="video-image"
        v-bind:src="this.video.video_url"
        preload="none"
        controls
        :hidden="this.init"
        @click="onPlayVideo()"
      />
    </div>
    <div class="d-flex flex-column video-desc">
      <span 
        v-if="video.channel"
        class="text-caption font-weight-bold channel-name"
        @click.stop="handleClickChannel"
      >
        {{ video.channel.name }}
      </span>
      <span class="text-caption text-title video-title" @click="onShowVideo()"> {{ video.title }} </span>
      <div class="d-flex justify-space-between align-center">
        <div class="d-flex flex-column">
          <span class="text-caption font-weight-bold">
            {{ (video.views || 0).toLocaleString() }} {{$t('views')}} • {{ (video.comments || 0).toLocaleString() }} {{$t('comments')}}
          </span>
          <span v-if="video.publish_time" class="text-caption font-weight-bold"
            >{{$t('date')}} {{ moment(video.publish_time).format("DD/MM/YYYY") }}
          </span>
        </div>
        <v-menu top offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="black" v-bind="attrs" v-on="on">
              <v-icon small>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list min-width="200" flat>
            <v-list-item-group class="mx-2">
              <v-list-item v-if="showAddSection" dense class="underline px-0" @click="$emit('addVideoToSection', video)">
                <v-list-item-title>
                  <span class="text-subtitle-2"> {{$t('add to session')}}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item dense class="underline px-0" @click="$emit('addVideoToPlaylist', video)">
                <v-list-item-title>
                  <span class="text-subtitle-2"> {{$t('add to playlist')}}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="showHotOptions" dense class="underline px-0" @click.stop="showRemoveFromRecommendedDlg = true">
                <v-list-item-title>
                  <span class="text-subtitle-2">{{$t('hide heating pad')}}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item dense class="underline px-0" @click="onEditVideo()">
                <v-list-item-title>
                  <span class="text-subtitle-2"> {{$t('edit video')}} </span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                dense
                v-if="showDownload"
                class="underline px-0"
                @click="downloadVideo(video)"
              >
                <v-list-item-title>
                  <span class="text-subtitle-2"> {{$t('download to computer')}} </span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                dense
                class="underline px-0"
                @click.stop="showDeleteDlg = true"
              >
                <v-list-item-title red--text>
                  <span class="text-subtitle-2 red--text"> {{$t('remove video')}} </span>
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-list-item class="justify-end px-2">
              <v-btn text>{{$t('closed')}}</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <v-dialog
        v-if="showHotOptions"
        v-model="showRemoveFromRecommendedDlg"
        max-width="480"
      >
        <v-card class="pa-10">
          <v-card-title class="text-h6 font-weight-bold pa-0 mb-5">
            {{$t('are you sure hide video from camera')}}
          </v-card-title>
          <div class="d-flex">
            <div>
              <v-img
                :width="100"
                :aspect-ratio="16 / 9"
                contain
                :src="video.thumb_url"
              />
            </div>
            <span class="mr-5 text-subtitle-2"> {{ video.title }} </span>
          </div>

          <v-card-actions class="d-flex justify-space-between px-0 mt-10">
            <v-btn
              class="white--text flex-grow-1"
              color="#616161"
              tile
              x-large
              @click="showRemoveFromRecommendedDlg = false"
            >
              <span class="text-h5">חזור</span>
            </v-btn>
            <v-btn
              class="white--text flex-grow-1"
              color="#ff0000"
              tile
              x-large
              @click="hideHotVideo()"
              :loading="loading"
              :disabled="loading"
            >
              <span class="text-h5">{{$t('hide')}}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showDeleteDlg" max-width="480">
        <v-card class="pa-10">
          <v-card-title class="text-h6 font-weight-bold pa-0 mb-5">
           {{$t('are you sure remove the video')}}
          </v-card-title>
          <div class="d-flex">
            <div>
              <v-img
                :width="100"
                :aspect-ratio="16 / 9"
                contain
                :src="video.thumb_url"
              />
            </div>
            <span class="mr-5 text-subtitle-2"> {{ video.title }} </span>
          </div>

          <v-card-actions class="d-flex justify-space-between px-0 mt-10">
            <v-btn
              class="white--text flex-grow-1"
              color="#616161"
              tile
              x-large
              @click="showDeleteDlg = false"
            >
              <span class="text-h5">{{$t('cancel')}}</span>
            </v-btn>
            <v-btn
              class="white--text flex-grow-1"
              color="#ff0000"
              tile
              x-large
              @click="onDeleteVideo()"
              :loading="loading"
              :disabled="loading"
            >
              <span class="text-h5">{{$t('delete')}}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import axios from "axios";

export default {
  props: {
    video: {
      id: Number,
      thumb_url: String,
      title: String,
      video_url: String,
      external_id: String,
      views: Number,
      publish_time: String,
    },
    showHotOptions: { Boolean, default: false },
    showDownload: { Boolean, default: false },
    showAddSection: { Boolean, default: false },
  },
  data() {
    return {
      init: true,
      playing: false,
      showDeleteDlg: false,
      loading: false,
      showRemoveFromRecommendedDlg: false,
    };
  },
  computed: {
    videoElement() {
        return this.$refs.video;
    },
    activeVideoId() {
        return this.$store.getters.getActiveVideoId;
    },
  },
  watch: {
    activeVideoId: {
        handler: function(activeVideoId) {
            if (this.video.id !== activeVideoId) {
                this.playing = false;
                this.videoElement.pause()
            }
        }
    }
  },
  methods: {
    forceFileDownload(response, video) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${video.id}.mp4`);
      document.body.appendChild(link);
      link.click();
    },
    downloadVideo(video) {
      axios
        .get(video.video_url, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          this.forceFileDownload(response, video);
        })
        .catch((ex) => console.error(ex.message));
    },
    onPlayVideo() {
        this.init = false
        this.playing = !this.playing
        this.playing ? this.videoElement.play() : this.videoElement.pause()
        this.$store.dispatch("SET_ACTIVE_VIDEO_ID", this.video.id);
    },
    onShowVideo() {
      return this.$router.push({
        path: `/channels/${this.video.channel.id}/videos/${this.video.id}`,
      });
    },
    onEditVideo() {
      return this.$router.push({
        path: `/channels/${this.video.channel.id}/videos/${this.video.id}/edit`,
      });
    },
    hideHotVideo() {
      this.loading = true;
      const params = {
        anonymous_hidden: true,
      };
      this.loading = true;
      api.editVideo(this.video.id, params)
        .then(() => {
          this.loading = false;
          this.showRemoveFromRecommendedDlg = false;
          this.$emit("refresh");
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.showRemoveFromRecommendedDlg = false;
        });
    },
    onDeleteVideo() {
      this.loading = true;
      const params = {
        hidden: true,
      };
      this.loading = true;
      api
        .editVideo(this.video.id, params)
        .then(() => {
          this.loading = false;
          this.showDeleteDlg = false;
          this.$emit("refresh");
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.showDeleteDlg = false;
        });
    },
    handleClickChannel() {
      this.$router.push({ path: `/channels/${this.video.channel_id}/dashboard` });
    }
  },
};
</script>

<style>
.test {
  border: 1px solid red;
}
.text-title {
  overflow: hidden;
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-width: 220px;
}
.channel-name {
  max-width: 220px;
}
.video-image {
  height: 120px;
  aspect-ratio: 16 / 9;
  background: black;
  object-fit: cover;
  background-size: cover;
  background-position: center center;
}
.video-desc {
  flex: 1;
  max-width: 240px;
}

.video-container {
  flex-direction: row;
}

.nowrap {
  white-space: nowrap;
}

@media (min-width: 200px) {
  .video-container {
    flex-direction: column;
  }
}

@media (min-width: 500px) {
  .video-container {
    flex-direction: row;
  }
}

@media (min-width: 600px) {
  .video-container {
    flex-direction: column;
  }
}

@media (min-width: 960px) {
  .video-container {
    flex-direction: row;
  }
}

@media (min-width: 1264px) {
  .video-container {
    flex-direction: column;
  }
}
@media (min-width: 1600px) {
  .video-container {
    flex-direction: row;
  }
}
</style>

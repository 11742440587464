<template>
  <v-navigation-drawer v-model="toggle" width="150" :right="$store.getters.getLanguage == 'he'"  app>
    <v-list>
      <v-list-item>
        <v-list-item-title>
          <span class="subtitle-2 ml-2"> {{ $t('hello') }} </span>
          <span class="subtitle-2 font-weight-bold">
            {{ this.$store.getters.getName }}
          </span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list flat dense>
      <v-list-item-group color="red" v-model="selectedIndex">
        <v-list-item disabled>
          <v-list-item-title class="font-weight-bold grey--text">
            {{ $t('channel management') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item :value="Number(1)" @click="changeRoute(`/channels/${activeChannelId}/dashboard`, 1)">
          <v-list-item-title> {{ $t('dashboard') }} </v-list-item-title>
        </v-list-item>
        <v-list-item :value="Number(2)"
          @click="changeRoute(`/channels/${activeChannelId}/library`, 2)"
        >
          <v-list-item-title> {{ $t('video library') }} </v-list-item-title>
        </v-list-item>
        <v-list-item :value="Number(3)"
          @click="changeRoute(`/channels/${activeChannelId}/playlist`, 3)"
        >
          <v-list-item-title> {{ $t('playlist') }} </v-list-item-title>
        </v-list-item>
         <v-list-item
          :value="Number(4)"
          @click="changeRoute(`/channels/${activeChannelId}/contact`, 4)"
        >
          <v-list-item-title> {{ $t('messages') }} </v-list-item-title>
        </v-list-item>
        <v-list-item
          :value="Number(5)"
          @click="changeRoute(`/channels/${activeChannelId}/story`, 5)"
        >
          <v-list-item-title> {{ $t('stories') }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          :value="Number(6)"
          @click="changeRoute(`/channels/${activeChannelId}/event`, 6)"
        >
          <v-list-item-title> {{ $t('events') }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          :value="Number(7)"
          @click="changeRoute(`/channels/${activeChannelId}/reports`, 7)"
        >
          <v-list-item-title> {{ $t('reports') }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          :value="Number(8)"
          @click="changeRoute(`/channels/${activeChannelId}/comments`, 8)"
        >
          <v-list-item-title> {{ $t('comments')}}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="isChannelAdmin"
          :value="Number(23)"
          @click="changeRoute(`/channels/${activeChannelId}/managers`, 23)"
        >
          <v-list-item-title> {{ $t('managers')}}</v-list-item-title>
        </v-list-item>
       
        <!-- <v-list-item
          @click="changeRoute(`/channels/${activeChannelId}/setting`, 7)"
        >
          <v-list-item-title> הגדרות </v-list-item-title>
        </v-list-item> -->
        <template v-if="isAdmin">
          <v-divider></v-divider>
          <v-list-item disabled>
            <v-list-item-title class="font-weight-bold grey--text">
              {{ $t('system management')}}
            </v-list-item-title>
          </v-list-item>
          <v-list-item :value="Number(9)" @click="changeRoute(`/feed`, 9)">
            <v-list-item-title> {{ $t('feed') }} </v-list-item-title>
          </v-list-item>
          <v-list-item :value="Number(10)" @click="changeRoute(`/app/analytics`, 10)">
            <v-list-item-title> {{ $t('analytics') }} </v-list-item-title>
          </v-list-item>
          <v-list-item :value="Number(11)" @click="changeRoute(`/app/recommended`, 11)">
            <v-list-item-title> {{ $t('recommended') }}  </v-list-item-title>
          </v-list-item>
          <v-list-item :value="Number(12)" @click="changeRoute(`/library`, 12)">
            <v-list-item-title> {{ $t('all videos') }} </v-list-item-title>
          </v-list-item>
          <v-list-item :value="Number(13)" @click="changeRoute(`/playlist`, 13)">
            <v-list-item-title> {{ $t('playlist') }} </v-list-item-title>
          </v-list-item>
          <v-list-item :value="Number(14)" @click="changeRoute(`/channels`, 14)">
            <v-list-item-title> {{ $t('channels') }}</v-list-item-title>
          </v-list-item>
          <v-list-item :value="Number(15)" @click="changeRoute(`/hevruta`, 15)">
            <v-list-item-title> {{ $t('hevruta') }}</v-list-item-title>
          </v-list-item>
          <v-list-item :value="Number(16)" @click="changeRoute(`/reports`, 16)">
            <v-list-item-title> {{ $t('all reports') }}</v-list-item-title>
          </v-list-item>
          <v-list-item :value="Number(17)" @click="changeRoute(`/users`, 17)">
            <v-list-item-title> {{ $t('users') }}</v-list-item-title>
          </v-list-item>
          <v-list-item :value="Number(18)" @click="changeRoute(`/categories`, 18)">
            <v-list-item-title> {{ $t('categories') }}</v-list-item-title>
          </v-list-item>
          <v-list-item :value="Number(19)" @click="changeRoute(`/places`, 19)">
            <v-list-item-title> {{ $t('places') }}</v-list-item-title>
          </v-list-item>
          <v-list-item :value="Number(20)" @click="changeRoute(`/events`, 20)">
            <v-list-item-title> {{ $t('classes') }}</v-list-item-title>
          </v-list-item>
          <v-list-item :value="Number(21)" @click="changeRoute(`/notifications`, 21)">
            <v-list-item-title> {{ $t('notifications') }}</v-list-item-title>
          </v-list-item>
          <v-list-item :value="Number(22)" @click="changeRoute(`/app/versions`, 22)">
            <v-list-item-title> {{ $t('version') }} </v-list-item-title>
          </v-list-item>
          <v-list-item :value="Number(23)" @click="changeRoute(`/commbox-conversations`, 23)">
            <v-list-item-title>Commbox</v-list-item-title>
          </v-list-item>
        </template>
         <v-list-item 
          :value="Number(100)"
          @click.stop="dialog = true">
          <v-list-item-title> {{ $t('logout') }} </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> {{ $t('logout') }} </v-card-title>
        <v-card-text> {{ $t('are you sure') }}? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
           {{ $t('cancel') }}
          </v-btn>
          <v-btn color="green darken-1" text @click="this.signout">
            {{ $t('logout') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      selectedIndex: 1,
      dialog: false,
    };
  },
  computed: {
    toggle: {
      get() {
        return this.$store.getters.getDrawer;
      },
      set(value) {
        this.$store.dispatch("SET_DRAWER", value);
      },
    },
    activeChannelId() {
      return this.$store.getters.getActiveChannelId;
    },
    isAdmin() {
      return this.$store.getters.getUser?.is_admin;
    },
    currentRouteName() {
        return this.$route.name;
    },
    isChannelAdmin() {
      const channelPermissions = this.$store.getters.getUser?.channel_permissions;
      if (!channelPermissions?.length) {
        return false;
      }
      const permission = channelPermissions.find((item) => item.channel_id === Number(this.activeChannelId || '0'));
      return permission?.role === 'admin'
    },
  },
  mounted() {
    this.$router.onReady(() => {
      switch (this.currentRouteName) {
        case 'Dashboard':
          this.selectedIndex = 1;
          break;
        case 'VideoLibrary':
          this.selectedIndex = 2;
          break;
        case 'Playlist':
          this.selectedIndex = 3;
          break;
        case 'ContactList':
          this.selectedIndex = 4;
          break;
        case 'Story':
          this.selectedIndex = 5;
          break;
        case 'Event':
          this.selectedIndex = 6;
          break;
        case 'Reports':
          this.selectedIndex = 7;
          break;
        case 'Comments':
          this.selectedIndex = 8;
          break;
        case 'Feed':
          this.selectedIndex = 9;
          break;
        case 'Analytics':
          this.selectedIndex = 10;
          break;
        case 'Recommended':
          this.selectedIndex = 11;
          break;
        case 'AllLibrary':
          this.selectedIndex = 12;
          break;
        case 'AdminPlaylist':
          this.selectedIndex = 13;
          break;
        case 'Channels':
          this.selectedIndex = 14;
          break;
        case 'Hevruta':
          this.selectedIndex = 15;
          break;
        case 'AllReports':
          this.selectedIndex = 16;
          break;
        case 'Users':
          this.selectedIndex = 17;
          break;
        case 'Categories':
          this.selectedIndex = 18;
          break;
        case 'Places':
          this.selectedIndex = 19;
          break;
        case 'Events':
          this.selectedIndex = 20;
          break;
        case 'Notification':
          this.selectedIndex = 21;
          break;
        case 'Versions':
          this.selectedIndex = 22;
          break;
        case 'Managers':
          this.selectedIndex = 23;
          break;
        default:
          this.selectedIndex = 1;
          break;
      }
    });
    
    
  },
  methods: {
    changeRoute(routerPath, selectedIndex) {
      const vm = this;
      vm.selectedIndex = selectedIndex;
      if (selectedIndex === 2 || selectedIndex === 12) {
        this.$store.dispatch("RESET_VIDEO_DATA");
      }
      return vm.$router.push({ path: routerPath });
    },
    signout() {
      this.dialog = false;
      this.$store.dispatch("LOGOUT");
      return this.$router.push({ path: "/login" });
    },
  },
};
</script>
<template>
    <div class="d-flex pa-2 video-container no-gutters">
        <div class="justify-right d-flex pl-2" >
            <div v-if="this.init" class="video-image d-flex justify-space-between align-end" :style="`background-image: url(${video.thumb_url})`"  >
                <v-chip color="#00000055" class="mb-3 mr-1 white--text" small label>
                {{this.$helpers.lengthInMinutes(this.video.duration)}}
                </v-chip>
                <v-btn
                    icon
                    color="gray"
                    class="mb-2 ml-1"
                    @click="onPlayVideo()"
                >
                 <v-icon size="20"  color="white">mdi-play</v-icon>
                </v-btn>
               
            </div>
            <video
                ref="video"
                class="video-image"
                v-bind:src="this.video.video_url"
                preload="none"
                :hidden="this.init"
                controls
                @click="onPlayVideo()"
            />
        </div>
        
        
        <div class="d-flex flex-column video-desc">
            <span 
                v-if="video.channel"
                class="text-caption font-weight-bold channel-name"
                @click="handleClickChannel"
            >
                {{ video.channel.name }}
            </span>
            <span class="text-caption text-title video-title" @click="onShowVideo()"> {{video.title}}</span>
            <div class="d-flex justify-space-between align-center">
                <div class="d-flex flex-column">
                    <span v-if="video.scheduled_at" class="text-caption font-weight-bold"
                        >תאריך {{ moment(video.scheduled_at).format("DD/MM/YYYY") }}
                    </span>
                     <v-btn @click="onPublish()" color="primary"  small text>
                         <span class="text-caption font-weight-bold blue--text">פרסם </span>
                     </v-btn>
                    
                    <!-- <span class="text-caption font-weight-bold mr-2">לארכיון </span> -->
                </div>
                <v-menu
                top
                offset-x
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="black"  v-bind="attrs" v-on="on">
                            <v-icon small>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list min-width="200" flat >
                        <v-list-item-group class="mx-2">
                            <v-list-item v-if="showAddSection" dense class="underline px-0" @click="$emit('addVideoToSection', video)">
                                <v-list-item-title>
                                <span class="text-subtitle-2"> הוסף לסקשן</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item dense class="underline px-0" @click="onEditVideo()">
                                <v-list-item-title >
                                    <span class="text-subtitle-2"> ערוך וידאו </span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item dense class="underline px-0" @click.stop="showDeleteDlg = true">
                                <v-list-item-title red--text>
                                    <span class="text-subtitle-2 red--text"> הסר וידאו </span>
                                 </v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                        <v-list-item class="justify-end px-2">
                            <v-btn text>סגור</v-btn>
                        </v-list-item>
                    </v-list>
                </v-menu>
                 
            </div>

            <v-dialog
            v-model="showDeleteDlg"
            max-width="480"
            >
            <v-card class="pa-10">
                <v-card-title class="text-h6 font-weight-bold pa-0 mb-5">
                האם הינך בטוח שאתה רוצה להסיר את הוידאו?
                </v-card-title>
                <div class="d-flex">
                    <div>
                        <v-img
                        :width="100"
                        :aspect-ratio="16/9"
                        contain
                        :src="video.thumb_url"
                        />
                    </div>
                    <span class="mr-5 text-subtitle-2"> {{video.title}} </span>
                </div>
                    
                <v-card-actions class="d-flex  justify-space-between px-0 mt-10">
                    <v-btn
                        class="white--text flex-grow-1"
                        color="#616161"
                        tile
                        x-large
                        @click="showDeleteDlg = false"
                    >
                        <span class="text-h5">חזור</span>
                    </v-btn>
                    <v-btn
                        class="white--text flex-grow-1"
                        color="#ff0000"
                        tile
                        x-large
                        @click="onDeleteVideo()"
                        :loading="loading" 
                        :disabled="loading"
                    >
                        <span class="text-h5">מחק</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import api from '@/api/api'

export default {
    props: {
        video: {
            id: Number,
            thumb_url: String,
            title: String,
            video_url: String,
            external_id: String,
            scheduled_at: String,
        },
        showAddSection: { Boolean, default: false },
    },
    data() {
        return {
            init: true,
            playing: false,
            showDeleteDlg: false,
            loading: false,
        }
    },
    computed: {
        activeChannelId() {
            return this.$store.getters.getActiveChannelId
        },
        videoElement() {
            return this.$refs.video;
        },
        activeVideoId() {
            return this.$store.getters.getActiveVideoId;
        },
    },
    watch: {
        activeVideoId: {
            handler: function(activeVideoId) {
                if (this.video.id !== activeVideoId) {
                    this.playing = false;
                    this.videoElement.pause()
                }
            }
        }
    },
    methods: {
        onPublish() {
            const params = {
                'status': 'published'
            }
            api.editVideo(this.video.id, params)
                .then(() => {
                    this.$emit('onPublished')
                }).catch(err => {
                    console.log(err)
                })
        },
        onPlayVideo() {
            this.init = false
            this.playing = !this.playing
            this.playing ? this.videoElement.play() : this.videoElement.pause()
            this.$store.dispatch("SET_ACTIVE_VIDEO_ID", this.video.id);
        },
        onShowVideo() {
           return this.$router.push({path:  `/channels/${this.activeChannelId}/videos/${this.video.id}`});
        },
        onEditVideo() {
            return this.$router.push({path: `/channels/${this.activeChannelId}/videos/${this.video.id}/edit`});
        },
        onDeleteVideo() {
            this.loading = true
            const params = {
                'hidden': true
            }
            this.loading = true;
            api.editVideo(this.video.id, params)
                .then(() => {
                    this.loading = false
                    this.showDeleteDlg = false
                    this.$emit('refresh')
                }).catch(err => {
                    console.log(err)
                    this.loading = false
                    this.showDeleteDlg = false
                })
        },
        handleClickChannel() {
            this.$router.push({ path: `/channels/${this.video.channel_id}/dashboard` });
        }
    }
}
</script>

<style>
    .test {
        border: 1px solid red;
    }
    .video-desc {
        flex: 1;
    }
    .underline {
        border-bottom: 1px solid black;
    }
    .video-title, .channel-name {
        cursor: pointer
    }
</style>
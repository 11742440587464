import Vue from 'vue'
import VueRouter from 'vue-router'
import { i18n } from '../main'

import DashboardPage from '../pages/dashboard/DashboardPage.vue';
import VideoLibrary from '../pages/video/VideoLibrary.vue';
import SettingPage from '../pages/settings/SettingPage.vue';
import EditChannel from '../pages/channel/EditChannel.vue';
import VideoDetail from '../pages/video/VideoDetail.vue';
import UploadVideo from '../pages/video/UploadVideo.vue';
import LoginPage from '../pages/login/LoginPage.vue';
import EditVideo from '../pages/video/EditVideo.vue';
import StoryPage from '../pages/story/StoryPage.vue';
import AddStoryPage from '../pages/story/AddStoryPage.vue';
import EventPage from '../pages/event/EventPage.vue';
import AddEventPage from '../pages/event/AddEventPage.vue';
import ReportsPage from '../pages/report/ReportsPage.vue';
import CommentsPage from '../pages/comment/CommentsPage.vue';
import HevrutaPage from '../pages/hevruta/HevrutaPage.vue';
import UsersPage from '../pages/user/UsersPage.vue';
import EditUser from '../pages/user/EditUser.vue';
import CategoriesPage from '../pages/category/CategoriesPage.vue';
import AddCategoryPage from '../pages/category/AddCategoryPage.vue';
import ChannelsPage from '../pages/channel/ChannelsPage.vue';
import RecommendedPage from '../pages/app/RecommendedPage.vue';
import AnalyticsPage from '../pages/app/AnalyticsPage.vue';
import AddPlacesPage from '../pages/places/AddPlacesPage.vue';
import PlacesPage from '../pages/places/PlacesPage.vue';
import FeedPage from '../pages/feed/FeedPage.vue';
import NotificationPage from '../pages/notification/NotificationPage.vue';
import AppVersionsPage from '../pages/app/version/AppVersionsPage.vue';
import AddAppVersionpage from '../pages/app/version/AddAppVersionPage.vue';
import NotFoundPage from '../pages/NotFound.vue';
import ContactListPage from '../pages/contact/ContactListPage.vue';
import ContactDetailsPage from '../pages/contact/ContactDetailsPage.vue';
import PlayListPage from '../pages/playlist/PlaylistPage.vue';
import PlaylistDetailsPage from '../pages/playlist/PlaylistDetailsPage.vue';
import SignUpPage from '../pages/signup/SignUp.vue';
import CommboxConversationsPage from '../pages/commbox/CommboxConversationsPage.vue';
import CommboxConversationDetailsPage from '../pages/commbox/CommboxConversationDetailsPage.vue';
import ManagersPage from '../pages/manager/ManagersPage.vue';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};
Vue.use(VueRouter)

const routes = [
    {   path: '*', 
        name: 'Not Found',
        component: NotFoundPage 
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginPage,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: '/channels/:channel_id/dashboard',
        name: 'Dashboard',
        component: DashboardPage
    },
    {
        path: '/channels/:channel_id/library',
        name: 'VideoLibrary',
        component: VideoLibrary
    },
    {
        path: '/channels/:channel_id/playlist',
        name: 'Playlist',
        component: PlayListPage,
    },
    {
        path: '/channels/:channel_id/playlist/:playlist_id',
        name: 'PlaylistDetails',
        component: PlaylistDetailsPage,
    },
    {
        path: '/channels/:channel_id/contact',
        name: 'ContactList',
        component: ContactListPage
    },
    {
        path: '/channels/:channel_id/contacts/:contact_id',
        name: 'ContactDetails',
        component: ContactDetailsPage
    },
    {
        path: '/channels/:channel_id/reports',
        name: 'Reports',
        component: ReportsPage
    },
    {
        path: '/channels/:channel_id/comments',
        name: 'Comments',
        component: CommentsPage
    },
    {
        path: '/channels/:channel_id/story',
        name: 'Story',
        component: StoryPage
    },
    {
        path: '/channels/:channel_id/story/add',
        name: 'AddStory',
        component: AddStoryPage
    },
    {
        path: '/channels/:channel_id/stories/:story_id/edit',
        name: 'EditStory',
        component: AddStoryPage
    },
    {
        path: '/channels/:channel_id/event/add',
        name: 'AddEvent',
        component: AddEventPage
    },
    {
        path: '/channels/:channel_id/events/:event_id/edit',
        name: 'EditEvent',
        component: AddEventPage
    },
    {
        path: '/channels/:channel_id/event',
        name: 'Event',
        component: EventPage
    },
    {
        path: '/channels/:channel_id/setting',
        name: 'Setting',
        component: SettingPage
    },
    {
        path: '/channels/:channel_id/edit',
        name: 'EditChannel',
        component: EditChannel
    },
    {
        path: '/channels/:channel_id/videos/:video_id',
        name: 'VideoDetail',
        component: VideoDetail
    },
    {
        path: '/channels/:channel_id/video/add',
        name: 'UploadVideo',
        component: UploadVideo
    },    
    {
        path: '/channels',
        name: 'Channels',
        component: ChannelsPage,
        meta: { adminAuth: true }
    },
    {
        path: '/channels/add',
        name: 'AddChannel',
        component: EditChannel,
        meta: { adminAuth: true }
    },
    {
        path: '/hevruta',
        name: 'Hevruta',
        component: HevrutaPage,
        meta: { adminAuth: true }
    },
    {
        path: '/reports',
        name: 'AllReports',
        component: ReportsPage,
        meta: { adminAuth: true }
    },
    {
        path: '/library',
        name: 'AllLibrary',
        component: VideoLibrary,
        meta: { adminAuth: true }
    },
    {
        path: '/playlist',
        name: 'AdminPlaylist',
        component: PlayListPage,
        meta: { adminAuth: true }
    },
    {
        path: '/playlist/:playlist_id',
        name: 'AdminPlaylistDetails',
        component: PlaylistDetailsPage,
        meta: { adminAuth: true }
    },
    {
        path: '/categories',
        name: 'Categories',
        component: CategoriesPage,
        meta: { adminAuth: true }
    },
    {
        path: '/categories/add',
        name: 'AddCategory',
        component: AddCategoryPage,
        meta: { adminAuth: true }
    },
    {
        path: '/categories/:category_id/edit',
        name: 'EditCategory',
        component: AddCategoryPage,
        meta: { adminAuth: true }
    },
    {
        path: '/users',
        name: 'Users',
        component: UsersPage,
        meta: { adminAuth: true }
    },
    {
        path: '/users/:id',
        name: 'EditUser',
        component: EditUser,
        meta: { adminAuth: true }
    },
    
    {
        path: '/app/recommended',
        name: 'Recommended',
        component: RecommendedPage,
        meta: { adminAuth: true }
    },
    {
        path: '/app/analytics',
        name: 'Analytics',
        component: AnalyticsPage,
        meta: { adminAuth: true }
    },
    {
        path: '/channels/:channel_id/videos/:video_id/edit',
        name: 'EditVideo',
        component: EditVideo
    },
    {
        path: '/places/add',
        name: 'AddPlace',
        component: AddPlacesPage,
        meta: { adminAuth: true }
    },
    {
        path: '/places/:place_id/edit',
        name: 'EditPlace',
        component: AddPlacesPage,
        meta: { adminAuth: true }
    },
    {
        path: '/places',
        name: 'Places',
        component: PlacesPage,
        meta: { adminAuth: true }
    },
    {
        path: '/events',
        name: 'Events',
        component: PlacesPage,
        meta: { adminAuth: true }
    },
    {
        path: '/events/add',
        name: 'AddEvents',
        component: AddPlacesPage,
        meta: { adminAuth: true }
    },
    {
        path: '/events/:place_id/edit',
        name: 'EditEvents',
        component: AddPlacesPage,
        meta: { adminAuth: true }
    },
    {
        path: '/feed',
        name: 'Feed',
        component: FeedPage,
        meta: { adminAuth: true }
    },
    {
        path: '/app/versions',
        name: 'Versions',
        component: AppVersionsPage,
        meta: { adminAuth: true }
    },
    {
        path: '/app/versions/add',
        name: 'AddVersion',
        component: AddAppVersionpage,
        meta: { adminAuth: true }
    },
    {
        path: '/notifications',
        name: 'Notification',
        component: NotificationPage
    },
    {
        path: '/library/video/add',
        name: 'AddVideo',
        component: UploadVideo,
        meta: { adminAuth: true }
    },
    {
        path: '/signup_via_link/:shortcode',
        name: 'SignUpViaLink',
        component: SignUpPage,
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: '/commbox-conversations',
        name: 'CommboxConversations',
        component: CommboxConversationsPage,
        meta: { adminAuth: true }
    },
    {
        path: '/commbox-conversations/:id',
        name: 'CommboxConversationDetails',
        component: CommboxConversationDetailsPage,
        meta: { adminAuth: true }
    },
    {
        path: '/channels/:channel_id/managers',
        name: 'Manager',
        component: ManagersPage
    },
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    i18n.locale = localStorage.getItem('language') || 'he'
    return next()
  })

export default router

<template>
  <v-container fluid>
    <v-row class="px-5 flex-column">
      <span v-if="rpts && !rpts.length" class="text-subtitle-2">
      {{$t('there are no reports')}}</span>
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <td class="text-center">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('display')}}</span>
                </td>
                <td class="text-center">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('title')}}</span>
                </td>
                <td class="text-center">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('channel name')}} </span>
                </td>
                <td class="text-center">
                  <span class="text-subtitle-2 font-weight-bold"> 
                  {{$t('reporting username')}}
                  </span>
                </td>
                <td class="text-center">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('address')}}</span>
                </td>
                <td class="text-center">
                  <span class="text-subtitle-2 font-weight-bold">
                    {{$t('reporting time')}}
                  </span
                  >
                </td>
                <td class="text-center">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('cause')}}</span>
                </td>
                <td class="text-center">
                  <span class="text-subtitle-2 font-weight-bold">{{$t('operations')}}</span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="report in rpts" :key="report.id">
                <td>
                  <div class="d-flex justify-center">
                    <div 
                      :style="`background-image: url(${report.video.thumb_url})`"
                      class="video-image d-flex justify-space-between align-end" >
                      <v-chip color="#00000055" class="mb-3 mr-1 white--text" small label>
                      {{$helpers.lengthInMinutes(report.video.duration)}}
                      </v-chip>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-center">
                    {{ report.video.title }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-center">
                    {{ report.channel.name }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-center">
                    {{ report.user.name }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-center">
                    {{ report.user.email }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-center">
                    {{ moment(report.created_at).format("M/d/Y") }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-center">
                    {{ getReason(report.reason) }}
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-right align-center">
                    <a
                      class="black--text font-weight-bold"
                      target="_blank"
                      :href="`https://kolel.org/videos/${report.video.id}`"
                      >{{$t('watch')}}
                    </a>	
                    <v-btn class="mr-2" text @click="ignore(report)">
                      <span class="text-subtitle-2 font-weight-bold">
                        <u>{{$t('ignore')}}</u>
                      </span>
                    </v-btn>
                    <v-btn class="mr-2" text @click="askRemove(report)">
                      <span class="text-subtitle-2 red--text font-weight-bold">
                        <u>{{$t('remove video')}}</u>
                      </span>
                    </v-btn>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-dialog v-model="showDeleteDlg" max-width="290">
            <v-card>
                <v-card-title class="text-subtitle-2" > {{$t('are you sure remove this video from feed')}} </v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="green darken-1" text @click="showDeleteDlg = false">
                    {{$t('cancel')}}
                </v-btn>

                <v-btn color="green darken-1" text @click="onRemoveFeed()">
                    {{$t('ok')}}
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> 
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import api from "@/api/api";

export default {
  data() {
    return {
      rpts: null,
      moment: moment,
      channelId: this.$route.params.channel_id,
      showDeleteDlg: false,
      selectedReport: null,
    };
  },
  watch: {
    $route(to) {
      if (to.params.channel_id) {
        this.channelId = to.params.channel_id;
        this.initLoad();
      }
    },
  },
  mounted() {
    this.initLoad();
  },
  methods: {
    initLoad() {
      if (this.channelId) {
        this.$store.dispatch("SET_ACTIVE_CHANNEL_ID", this.channelId);
      }
      this.getData();
    },
    getData() {
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      const params = { channel_id: this.channelId };
      api
        .getChannelReports(params)
        .then((res) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          if (res.data && res.data.reports.length > 0) {
            this.rpts = res.data.reports;
          } else {
            this.rpts = [];
          }
        })
        .catch((error) => {
          this.$store.dispatch("SET_TOOLBARLOAD", false);
          console.log(error);
        });
    },
    ignore(report) {
      api.ignoreReport(report)        
        .then(() => {
          this.getData();
        });
    },
    askRemove(report) {
      this.showDeleteDlg = true;
      this.selectedReport = report;
      
    },
    onRemoveFeed() {
      if (!this.selectedReport) return;
      this.showDeleteDlg = false;
      this.$store.dispatch("SET_TOOLBARLOAD", true);
      api.deleteVideo(this.selectedReport.video.id)
          .then(() => {
              this.$store.dispatch("SET_TOOLBARLOAD", false);
              this.getData();
          })
          .catch((err) => {
              this.$store.dispatch("SET_TOOLBARLOAD", false);
              console.log(err);
              this.getData();
          })
    },
    getReason(reason) {
      switch (reason) {
        case 1:
          return "הפרת זכויות יוצרים";
        case 2:
          return "מקדם אלימות או שנאה";
        case 3:
          return "לא מתאים לאפליקציה";
        case 4:
          return "תוכן ספאם";
        default:
          return "לא ידוע";
      }
    },
  },
};
</script>
<style scoped>
.video-image {
  width: 200px;
  height: 120px;
}
</style>